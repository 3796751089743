import { Injectable } from '@angular/core';
import { map, Observable, of, switchMap, tap } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { ResidentGender } from 'src/app/modules/resident/models/profile-form.model';
import { Dictionary, GenericResponse } from 'src/app/shared/models/global';
import { FamilyInviteStatus, ResidentFamily } from '@resparke/models';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { getSydneyDate } from '@resparke/utils';

export interface Resident {
  id: string;
  facilityId: string;
  preferenceId: string;
  firstName: string;
  lastName: string;
  preferredName?: string;
  roomNumber: string;
  program?: string[];
  locked?: boolean;
  familyStatus?: {
    status: FamilyInviteStatus
    dateUpdated?: number;
  }[];
}

@Injectable({
  providedIn: 'root'
})
export class ManageResidentService {

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private appState: AppStateService,
  ) { }

  addResident(input: {
    facilityId: string;
    dob: string;
    firstName: string;
    lastName: string;
    preferredName: string;
    gender: ResidentGender;
    consent: boolean;
    applicant: string;
    roomNumber: string;
    countryOfBirth: string;
    primaryLanguage: string;
    religion: string;
    practiceReligion: boolean;
    suggestedReligion: string[],
  }) {

    const statement = `
      mutation addResidentV2($input: AddResidentInput!) {
        addResidentV2(input: $input) {
          id
          firstName
          lastName
          preferredName
          facilityId
          roomNumber
          preferenceId
          program
        }
      }
    `;
    return this.apiService
      .graphql<Resident>({ statement, variables: { input }, type: 'addResidentV2' })
      .pipe(
        tap(resident => {
          if (resident) {
            const residentsCol = this.appState.get<Dictionary<Resident[]>>('residentsCollection') || {};
            resident.familyStatus = [{ status: FamilyInviteStatus.DETAILS_REQUIRED }];
            residentsCol[input.facilityId] = [...residentsCol[input.facilityId], resident];
            this.appState.setState('residentsCollection', residentsCol);
          } else {
            console.error('Error creating resident');
          }
        })
      );
  }

  getResidents(facilityId: string): Observable<Resident[]> {

    const statement = `
      query getResidentsV2($facilityId: ID!) {
        getResidentsV2(facilityId: $facilityId) {
          id
          firstName
          lastName
          preferredName
          roomNumber
          preferenceId
          program
          locked
          familyStatus {
            status
            dateUpdated
          }
        }
      }
    `;
    return this.apiService
      .graphql<Resident[]>({ statement, variables: { facilityId }, type: 'getResidentsV2' })
      .pipe(
        map(residents => residents.length ?
          residents.sort((a, b) => a.lastName.toLowerCase() > b.lastName.toLowerCase() ? 1 : -1) : []
        ),
        map(residents => residents.map(resident => ({
          ...resident,
          familyStatus: Array.isArray(resident.familyStatus) && resident.familyStatus.length ? resident.familyStatus.reverse() : [{ status: FamilyInviteStatus.DETAILS_REQUIRED }]
        }))),
        tap(residents => {
          const residentsCol = this.appState.get<Dictionary<Resident[]>>('residentsCollection') || {};
          residentsCol[facilityId] = residents;
          this.appState.setState('residentsCollection', residentsCol);
        })
      )
  }

  getFamilyMember(input: { residentId: string }) {
    const statement = `
      query getFamilyMember($input: GetFamilyInput!) {
        getFamilyMember(input: $input) {
          id
          firstName
          lastName
          email
        }
      }
    `;
    return this.apiService
      .graphql<ResidentFamily>({ statement, variables: { input }, type: 'getFamilyMember', familyApi: true })
  }

  deleteResident(input: {
    facilityId: string;
    residentId: string;
    deleted?: boolean;
  }) {
    const statement = `
    mutation deleteResidentV2($input: DeleteResidentInput!) {
      deleteResidentV2(input: $input)
    }
  `;
    return this.apiService
      .graphql<string>({ statement, variables: { input }, type: 'deleteResidentV2' })
      .pipe(
        tap(() => {
          const residentsCol = this.appState.get<Dictionary<Resident[]>>('residentsCollection') || {};
          residentsCol[input.facilityId] = [...residentsCol[input.facilityId].filter(res => res.id !== input.residentId)];
          this.appState.setState('residentsCollection', residentsCol);
        })
      )
  }

  getFamilyLink(input: { residentId: string, facilityId: string, email: string }) {
    console.log('input' , input)
    const statement = `
    mutation getFamilyLink($input: GetFamilyLinkInput!) {
      getFamilyLink(input: $input)
    }
  `;
    return this.apiService
      .graphql<string>({ statement, variables: { input }, type: 'getFamilyLink', familyApi: true })
  }

  addFamily(input: { firstName: string, lastName: string, email: string, residentId: string, facilityId: string }) {

    const statement = `
      mutation addFamily($input: AddFamilyInput!) {
        addFamily(input: $input) {
          status
          message
        }
      }
    `;
    return this.apiService
      .graphql<GenericResponse>({ statement, variables: { input }, type: 'addFamily', familyApi: true})
      .pipe(
        tap(() => {
          const residentsCol = this.appState.get<Dictionary<Resident[]>>('residentsCollection') || {};
          const resident = residentsCol[input.facilityId].find(resident => resident.id === input.residentId);
          resident.familyStatus = [{ status: FamilyInviteStatus.DETAILS_SAVED, dateUpdated: getSydneyDate().valueOf() }, ...resident.familyStatus];
          this.appState.setState('residentsCollection', residentsCol);
        })
      )
  }

  inviteFamilyMember(input: { email: string, familyFirstName: string, residentFirstName: string, residentLastName: string, facilityName: string, facilityDisplayName: string, residentId: string, facilityId: string}) {
    const statement = `
      mutation inviteFamilyMember($input: InviteFamilyMemberInput!) {
        inviteFamilyMember(input: $input) {
          status
          message
        }
      }
    `;
    return this.apiService
      .graphql<GenericResponse>({ statement, variables: { input }, type: 'addFamily', familyApi: true})
      .pipe(
        tap(() => {
          const residentsCol = this.appState.get<Dictionary<Resident[]>>('residentsCollection') || {};
          const resident = residentsCol[input.facilityId].find(resident => resident.id === input.residentId);
          resident.familyStatus = [{ status: FamilyInviteStatus.DETAILS_SENT, dateUpdated: getSydneyDate().valueOf() }, ...resident.familyStatus];
          this.appState.setState('residentsCollection', residentsCol);
        })
      )
  }

  updateResidentProgram(input: { facilityId: string, residentId: string, program: string[] }) {
    const statement = `
    mutation updateResidentProgram($input: ResidentProgramInput!) {
      updateResidentProgram(input: $input)
    }
  `;
    return this.apiService
      .graphql<string>({ statement, variables: { input }, type: 'updateResidentProgram' })
  }

  lockResident(residentId: string) {
    const input = {
      residentId,
    }
    const statement = `
    mutation lockResident($input: LockResidentInput!) {
      lockResident(input: $input) {
        status
        message
        payload
      }
    }
  `;
    return this.apiService
      .graphql<GenericResponse>({ statement, variables: { input }, type: 'lockResident' })
      .pipe(
        map<GenericResponse, { username: string, tokenBody: string }>(data => JSON.parse(data.payload)),
        tap(() => this.authService.removeDeviceKey(residentId)),
        switchMap(data => {
          const authChallenge = decodeURIComponent(data.tokenBody);
          return this.authService.customChallengeLogin({
            username: data.username,
            challenge: authChallenge
          });
        })
      )
  }

  unlockResident(residentId: string, reAuthenticate: boolean) {
    const input = {
      residentId,
      reAuthenticate,
    }
    const statement = `
      mutation unlockResident($input: UnlockResidentInput!) {
        unlockResident(input: $input) {
          status
          message
          payload
        }
      }`;
    return this.apiService
      .graphql<GenericResponse>({ statement, variables: { input }, type: 'unlockResident' })
      .pipe(
        map<GenericResponse, { username: string, tokenBody: string, status?: string }>(data => JSON.parse(data.payload)),
        switchMap(data => {
          console.log(residentId);
          const facilityId = this.appState.get<string>('currentFacilityId');
          // remove locked resident from user collection
          const users = (this.appState.getUserCollection(facilityId) || [])
            .filter(val => val.userName !== residentId);
          console.log(users);
          this.appState.setUserCollection(facilityId, users);

          // only reAuthenticate if we have a tokenBody
          if (reAuthenticate && data.tokenBody) {
            return this.authService.passwordlessLogin(data);
          }
          return of(data);
        })
      );
  }
}


